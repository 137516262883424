<template>
  <div
    ref="animatedContent"
    class="h-100 w-100"
    :class="{
      'animation-enabled': enableAnimation,
      'animation-ended': animationEnded,
    }"
    :style="animatedContentStyle"
  >
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import gsap from "gsap";
const props = withDefaults(
  defineProps<{
    enableAnimation?: boolean;
  }>(),
  {
    enableAnimation: false,
  }
);

const animatedContent = ref<HTMLElement | null>(null);
const animatedContentStyle = computed(() => {
  const initailScale = props.enableAnimation ? 0.75 : 1;
  return {
    transform: `scale(${initailScale})`,
  };
});

const animationEnded = ref(false);

const updateAnimation = () => {
  const scale = props.enableAnimation ? 0.75 : 1;
  gsap.fromTo(
    animatedContent.value,
    { scale },
    {
      scale: 1,
      duration: 1.6,
      delay: 0.4,
      scrollTrigger: {
        trigger: animatedContent.value,
        start: "top center",
        toggleActions: "play none none none",
      },
      onComplete: () => {
        animationEnded.value = true;
      },
    }
  );
};

watch(
  () => props.enableAnimation,
  () => {
    updateAnimation();
  }
);

onMounted(() => {
  updateAnimation();
});
</script>
