<template>
  <div class="block-list">
    <component
      v-for="(block, index) in blocks"
      :key="index"
      :is="getComponentForBlock(block.acf_fc_layout)"
      :data="block.data"
      :options="block.options || {}"
      :spacing="block.spacing || {}"
    />
  </div>
</template>

<script setup lang="ts">
import HeroSliderWithFilters from "./blocks/HeroSliderWithFilters.vue";
import BlockGroup from "./blocks/BlockGroup.vue";
import AdvancedContent from "./blocks/AdvancedContent.vue";
import ParallaxMediaCards from "./blocks/ParallaxMediaCards.vue";
import HorizontalCardsAccordion from "./blocks/HorizontalCardsAccordion.vue";
import HorizontalSection from "./blocks/HorizontalSection.vue";
import ProductsCarousel from "./blocks/ProductsCarousel.vue";
import ArticleCardsCarousel from "./blocks/ArticleCardsCarousel.vue";
import GenericCardCarousel from "./blocks/GenericCardCarousel.vue";
import GroupGalleries from "./blocks/GroupGalleries.vue";
import MasonryCards from "./blocks/MasonryCards.vue";
import Banner from "./blocks/Banner.vue";
import SliderBanners from "./blocks/SliderBanners.vue";
import SwipeableCardsCarouselContentAside from "./blocks/SwipeableCardsCarouselContentAside.vue";
import SliderTwoColumns from "./blocks/SliderTwoColumns.vue";
import SliderTwoColumnswithAccordion from "./blocks/SliderTwoColumnsAccordion.vue";
import MapSelector from "./blocks/split-maps/MapSelector.vue";
import HorizontalMedias from "./blocks/HorizontalMedias.vue";
import ContentBannerCards from "./blocks/ContentBannerCards.vue";
import BannerInfoBlock from "./blocks/BannerInfoBlock.vue";
import ProductBanner from "./blocks/ProductBanner.vue";
import PricedCardsCarousel from "./blocks/PricedCardsCarousel.vue";
import EntrancesFees from "./blocks/EntrancesFees.vue";
import Faq from "./blocks/Faq.vue";
import InfoSection from "./blocks/InfoSection.vue";
import ToggleableFeatureList from "./blocks/ToggleableFeatureList.vue";
import MediaCards from "./blocks/MediaCards.vue";
import CardGrid from "./blocks/CardGrid.vue";
import DestinationList from "./blocks/DestinationList.vue";
import TwoColumnsAndCards from "./blocks/TwoColumnsAndCards.vue";
import TwoColumnsAndCardsOverlaySlider from "./blocks/TwoColumnsAndCardsOverlaySlider.vue";
import ProductSwiper from "./blocks/ProductSwiper.vue";
import Conventions from "./blocks/Conventions.vue";
import Spacer from "./blocks/SpacerModule.vue";
import GridIcons from "./blocks/GridIcons.vue";
import TwoColumnsProductBanner from "./blocks/TwoColumnsProductBanner.vue";
import IconCards from "./blocks/IconCards.vue";
import TwoColumnsAndOverlayGallery from "./blocks/TwoColumnsAndOverlayGallery.vue";
import TwoColumnsWideMedia from "./blocks/TwoColumnsWideMedia.vue";
import Careers from "./blocks/Careers.vue";
import CardGridWithPopup from "./blocks/CardGridWithPopup.vue";
import RadialProductCarousel from "./blocks/RadialProductCarousel.vue";
import AnimatedCurtain from "./blocks/AnimatedCurtain.vue";
import DownloadableDocumentlist from "./blocks/DownloadableDocumentList.vue";
import CardsQuantity from "./blocks/CardsQuantity.vue";
import HubPage from "./blocks/HubPage.vue";
import TablistMediaSlider from "./blocks/TablistMediaSlider.vue";
import BusinessSearchFilters from "./blocks/BusinessSearchFilters.vue";
import Timeline from "./blocks/Timeline.vue";

const { $lsUpdate } = useNuxtApp();

const props = defineProps<{
  blocks: Array<{
    acf_fc_layout: keyof typeof blockMap;
    data: Record<any, any>;
    options?: Record<any, any>;
    spacing?: Record<any, any>;
  }>;
}>();

const blockMap = {
  hero_slider_with_filters: HeroSliderWithFilters,
  block_group: BlockGroup,
  advanced_content: AdvancedContent,
  parallax_media_cards: ParallaxMediaCards,
  horizontal_cards_accordion: HorizontalCardsAccordion,
  horizontal_section: HorizontalSection,
  products_carousel: ProductsCarousel,
  article_cards_carousel: ArticleCardsCarousel,
  group_galleries: GroupGalleries,
  masonry_cards: MasonryCards,
  slider_banners: SliderBanners,
  banner: Banner,
  split_maps: MapSelector,
  slider_two_columns: SliderTwoColumns,
  slider_two_columns_with_accordion: SliderTwoColumnswithAccordion,
  swipeable_cards_carousel_content_aside: SwipeableCardsCarouselContentAside,
  horizontal_medias: HorizontalMedias,
  content_banner_cards: ContentBannerCards,
  banner_info_block: BannerInfoBlock,
  generic_card_carousel: GenericCardCarousel,
  product_banner: ProductBanner,
  radial_product_carousel: RadialProductCarousel,
  priced_cards_carousel: PricedCardsCarousel,
  entrances_fees: EntrancesFees,
  faq: Faq,
  info_section: InfoSection,
  media_cards: MediaCards,
  toggleable_feature_list: ToggleableFeatureList,
  card_grid: CardGrid,
  destination_list: DestinationList,
  two_columns_and_cards: TwoColumnsAndCards,
  two_columns_and_cards_overlay_slider: TwoColumnsAndCardsOverlaySlider,
  product_swiper: ProductSwiper,
  conventions: Conventions,
  spacer: Spacer,
  two_columns_product_banner: TwoColumnsProductBanner,
  grid_icons: GridIcons,
  two_columns_and_overlay_gallery: TwoColumnsAndOverlayGallery,
  two_columns_wide_media: TwoColumnsWideMedia,
  icon_cards: IconCards,
  careers: Careers,
  card_grid_with_popup: CardGridWithPopup,
  animated_curtain: AnimatedCurtain,
  downloadable_document_list: DownloadableDocumentlist,
  cards_quantity: CardsQuantity,
  tablist_media_slider: TablistMediaSlider,
  business_search_filters: BusinessSearchFilters,
  hub_page: HubPage,
  timeline: Timeline,
};

const getComponentForBlock = (blockName: keyof typeof blockMap) => {
  return blockMap[blockName] || null;
};
</script>
